import React from 'react'
import { useRouter } from 'next/router'
import { Card, Result } from 'antd'
import Button from '@/components/Button'

const NotFoundPage = () => {
  const router = useRouter()
  return (
    <div className="flex h-full items-center justify-center">
      <Card>
        <Result
          status="404"
          title="404"
          subTitle="灵缇发现这个页面不存在，请输入正确的地址哦"
          extra={
            <Button type="primary" onClick={() => router.push('/')}>
              返回首页
            </Button>
          }
        />
      </Card>
    </div>
  )
}

export default NotFoundPage
